import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button, Stack } from "@mui/material";

import { useDocumentDetailsFooterContext } from "./FooterContext";
import { DocumentDetailsFooterType } from "./types";

interface DocumentDetailsFooterButtonsProps {
  setDrawerIsVisible: (drawerIsVisible: boolean) => void;
}

export function DocumentDetailsFooterButton(props: DocumentDetailsFooterButtonsProps) {
  const { setDrawerIsVisible } = props;

  const { footerType, finishAndUpload, startStripeFlow, deleteDocument } =
    useDocumentDetailsFooterContext();

  switch (footerType) {
    case DocumentDetailsFooterType.UPLOAD_REPLACEMENT: {
      return (
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            setDrawerIsVisible(true);
          }}
        >
          Upload Replacement Document
        </Button>
      );
    }

    case DocumentDetailsFooterType.ADD_MORE_IMAGES_AND_FINISH_AND_UPLOAD: {
      return (
        <Stack direction="column" spacing={1}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setDrawerIsVisible(true);
            }}
          >
            <Stack alignItems="center" direction="row" spacing={1}>
              <CameraAltIcon />
              <span>Add more images</span>
            </Stack>
          </Button>

          <Button fullWidth variant="contained" onClick={finishAndUpload}>
            <Stack alignItems="center" direction="row" spacing={1}>
              <CloudUploadIcon />
              <span>Finish and Upload</span>
            </Stack>
          </Button>
        </Stack>
      );
    }

    case DocumentDetailsFooterType.FINISH_AND_UPLOAD: {
      return (
        <Button fullWidth variant="contained" onClick={finishAndUpload}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <CloudUploadIcon />
            <span>Finish and Upload</span>
          </Stack>
        </Button>
      );
    }

    case DocumentDetailsFooterType.VERIFY_ID_WITH_STRIPE: {
      return (
        <Button fullWidth variant="contained" onClick={startStripeFlow}>
          Verify ID With Stripe
        </Button>
      );
    }

    case DocumentDetailsFooterType.DELETE_DOCUMENT: {
      return (
        <Button fullWidth variant="contained" onClick={deleteDocument}>
          Delete Uploaded Document
        </Button>
      );
    }

    default: {
      return (
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            setDrawerIsVisible(true);
          }}
        >
          Upload Document
        </Button>
      );
    }
  }
}
