import { Text } from "@clipboard-health/ui-react";
import CheckCircleFilledIcon from "@mui/icons-material/CheckCircle";
import { Box, Card, FormControlLabel, Radio } from "@mui/material";

interface PersonalIdOptionProps {
  isSelected: boolean;
  label: string;
  value: string;
}

export function PersonalIdOption(props: PersonalIdOptionProps) {
  const { isSelected, value, label } = props;
  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      {isSelected && (
        <CheckCircleFilledIcon
          color="primary"
          sx={{
            position: "absolute",
            top: -8,
            right: -8,
            zIndex: 1,
            backgroundColor: "white",
            borderRadius: "50%",
          }}
        />
      )}
      <FormControlLabel
        sx={{
          "&.MuiFormControlLabel-root": {
            marginLeft: 0,
            width: "100%",
            height: "100%",
            display: "block",
          },
        }}
        value={value}
        control={<Radio sx={{ display: "none" }} />}
        label={
          <Card
            sx={{
              minWidth: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              position: "relative",
              paddingY: 1,
              paddingX: 2,
              minHeight: 64,
              ...(isSelected
                ? {
                    borderColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.main,
                    backgroundColor: (theme) => `${theme.palette.primary.main}10`,
                  }
                : {}),
            }}
            variant="outlined"
          >
            <Text
              sx={{
                ...(isSelected
                  ? {
                      borderColor: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.primary.main,
                    }
                  : {}),
              }}
            >
              {label}
            </Text>
          </Card>
        }
      />
    </Box>
  );
}
