import { Text } from "@clipboard-health/ui-react";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { Stack } from "@mui/material";
import { type ReactElement } from "react";

interface DocumentDetailsChecklistItemsProps {
  items: string[];
}

export function DocumentDetailsChecklistItems(
  props: DocumentDetailsChecklistItemsProps
): ReactElement {
  const { items } = props;

  return (
    <Stack spacing={1} sx={{ color: (theme) => theme.typography.body2.color }}>
      {items.map((item) => (
        <Stack key={item} alignItems="center" direction="row" spacing={1}>
          <CheckBoxOutlinedIcon color="inherit" />
          <Text variant="h3" color="inherit">
            {item}
          </Text>
        </Stack>
      ))}
    </Stack>
  );
}
