import { Skeleton, Stack } from "@mui/material";
import { type ReactElement } from "react";

export function DocumentDetailsPageSkeleton(): ReactElement {
  return (
    <Stack spacing={3}>
      {[1, 2, 3].map((value: number) => {
        return (
          <Stack key={value} spacing={2}>
            <Skeleton animation="wave" width="75%" />
            <Skeleton height="120px" variant="rectangular" animation="wave" />
          </Stack>
        );
      })}
    </Stack>
  );
}
