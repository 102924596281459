import { FormControl, Grid, RadioGroup } from "@mui/material";
import { type ChangeEvent } from "react";

import { PersonalIdOption } from "./PersonalIdOption";

interface PersonalIdOptionPickerProps {
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  selectedValue?: string;
  options: Array<{
    label: string;
    value: string;
  }>;
}

export function PersonalIdOptionPicker(props: PersonalIdOptionPickerProps) {
  const { onChange, selectedValue, options } = props;
  return (
    <FormControl component="fieldset">
      <RadioGroup value={selectedValue} onChange={onChange}>
        <Grid
          container
          spacing={2}
          sx={{
            alignItems: "stretch",
            gridTemplateColumns: "1fr",
            gridAutoFlow: "column",
          }}
        >
          {options.map((option) => {
            const { value, label } = option;
            return (
              <Grid key={value} item xs={6}>
                <PersonalIdOption
                  value={value}
                  label={label}
                  isSelected={value === selectedValue}
                />
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
}
