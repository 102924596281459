import { CameraSource } from "@capacitor/camera";
import { type UseModalState } from "@clipboard-health/ui-react";
import { OpenNativeSettings } from "@ionic-native/open-native-settings";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { isCapacitorPlatform } from "@src/appV2/lib";

import { type AllowedCameraSourceType } from "../../types";

interface CameraPermissionRequiredDialogProps {
  modalState: UseModalState;
  cameraSource: AllowedCameraSourceType;
}

export function CameraPermissionRequiredDialog(props: CameraPermissionRequiredDialogProps) {
  const { cameraSource, modalState } = props;
  const { modalIsOpen, closeModal } = modalState;

  return (
    <Dialog open={modalIsOpen} onClose={closeModal}>
      <DialogContent dividers>
        <DialogTitle>
          Allow {cameraSource === CameraSource.Camera ? "Camera" : "Gallery"} Permissions
        </DialogTitle>
        <DialogContentText
          sx={{
            textAlign: "center",
          }}
        >
          We need access to your{" "}
          {cameraSource === CameraSource.Camera ? "camera" : "gallery photos"} to continue with this
          action. Please give access to this in your settings.
        </DialogContentText>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            onClick={async () => {
              if (isCapacitorPlatform()) {
                await OpenNativeSettings.open("application_details");
              }
            }}
          >
            Go to settings
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
