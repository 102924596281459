import { Text } from "@clipboard-health/ui-react";
import { Alert, type AlertColor, Box, Stack } from "@mui/material";
import { type ReactElement, type ReactNode } from "react";

import { RequirementStatus } from "../../types";
import { useDocumentDetailsContext } from "../../useDocumentDetails";
import { DocumentDetailsViewDocumentButton } from "../ViewDocumentButton";

export interface RequirementStatusAlertProps {
  title: string;
  status: RequirementStatus;
  children: ReactNode;
}

const alertTypeMapping: Record<RequirementStatus, AlertColor> = {
  [RequirementStatus.PENDING]: "info",
  [RequirementStatus.APPROVED]: "success",
  [RequirementStatus.SOON_TO_EXPIRE]: "warning",
  [RequirementStatus.REJECTED]: "error",
  [RequirementStatus.EXPIRED]: "error",
};

export function RequirementStatusAlert(props: RequirementStatusAlertProps): ReactElement {
  const { title, status, children } = props;

  const { viewDocumentButtonIsVisible } = useDocumentDetailsContext();
  const requirementStatus = status ?? RequirementStatus.PENDING;

  return (
    <Alert severity={alertTypeMapping[requirementStatus]}>
      <Stack spacing={1}>
        <Text bold variant="h2" color="inherit" sx={{ paddingLeft: 1 }}>
          {title}
        </Text>
        <Box
          sx={{
            paddingLeft: 1,
          }}
        >
          {children}
        </Box>
      </Stack>
      {viewDocumentButtonIsVisible && (
        <Box>
          <DocumentDetailsViewDocumentButton />
        </Box>
      )}
    </Alert>
  );
}
