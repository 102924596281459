import { Drawer } from "@mui/material";
import { useState } from "react";

import { DocumentDetailsDrawerItems } from "./DrawerItems";
import { DocumentDetailsFooterButton } from "./FooterButton";

export function DocumentDetailsFooter() {
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);

  return (
    <>
      <DocumentDetailsFooterButton setDrawerIsVisible={setDrawerIsVisible} />
      <Drawer
        anchor="bottom"
        open={drawerIsVisible}
        PaperProps={{
          sx: {
            paddingTop: 1,
          },
        }}
        onClose={() => {
          setDrawerIsVisible(false);
        }}
      >
        <DocumentDetailsDrawerItems setDrawerIsVisible={setDrawerIsVisible} />
      </Drawer>
    </>
  );
}
