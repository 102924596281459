import { isDefined } from "@clipboard-health/util-ts";

import { ACCOUNTS_DOCUMENTS_DETAILS_FULL_PATH, type DocumentRequirementType } from "../Documents";

interface GenerateDocumentDetailsPath {
  hcfId?: string;
  requirement?: string;
  requirementStatus?: DocumentRequirementType;
}

export function generateDocumentDetailsPath(props: GenerateDocumentDetailsPath) {
  const { hcfId, requirement, requirementStatus } = props;

  const queryParams = new URLSearchParams({
    ...(isDefined(hcfId) && { hcfId }),
    ...(isDefined(requirement) && { requirement }),
    ...(isDefined(requirementStatus) && { requirementStatus }),
  }).toString();

  if (queryParams === "") {
    return ACCOUNTS_DOCUMENTS_DETAILS_FULL_PATH;
  }

  return `${ACCOUNTS_DOCUMENTS_DETAILS_FULL_PATH}?${queryParams}`;
}
