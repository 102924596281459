import { type AllowedCameraSourceType } from "../types";

interface CameraPermissionDeniedErrorParams {
  message: string;
  cameraSource: AllowedCameraSourceType;
}
export class CameraPermissionDeniedError extends Error {
  private readonly cameraSource: AllowedCameraSourceType;

  constructor(params: CameraPermissionDeniedErrorParams) {
    const { message, cameraSource } = params;
    super(message);
    this.message = message;
    this.cameraSource = cameraSource;
  }

  public getCameraSource() {
    return this.cameraSource;
  }
}
