import { isDefined } from "@clipboard-health/util-ts";
import { Button, Link } from "@mui/material";
import { type ReactElement } from "react";

import { useDocumentDetailsContext } from "../../useDocumentDetails";

export function DocumentDetailsViewDocumentButton(): ReactElement {
  const { viewDocumentOnClick, documentId } = useDocumentDetailsContext();

  return (
    <Link
      component={Button}
      sx={{
        fontSize: (theme) => theme.typography.h3.fontSize,
      }}
      onClick={async () => {
        if (isDefined(documentId)) {
          await viewDocumentOnClick(documentId);
        }
      }}
    >
      View document
    </Link>
  );
}
