import { isDefined } from "@clipboard-health/util-ts";

import { DocumentRequirementType } from "../../../../Documents/types";
import { FileSelectionType, type UploadFileData } from "../../../types";
import { DocumentDetailsFooterType } from "../types";

export interface GetDocumentDetailsFooterTypeProps {
  isStripeVerificationFlow: boolean;
  requirementType?: DocumentRequirementType;
  fileSelectionType?: FileSelectionType;
  selectedFiles: UploadFileData[];
}

const ReplaceableDocumentRequirementTypes = new Set([
  DocumentRequirementType.COMPLETED,
  DocumentRequirementType.EXPIRED,
  DocumentRequirementType.REJECTED,
]);

export function getDocumentDetailsFooterType(
  props: GetDocumentDetailsFooterTypeProps
): DocumentDetailsFooterType {
  const { isStripeVerificationFlow, requirementType, fileSelectionType, selectedFiles } = props;

  if (requirementType === DocumentRequirementType.PENDING) {
    return DocumentDetailsFooterType.DELETE_DOCUMENT;
  }

  if (isStripeVerificationFlow) {
    return DocumentDetailsFooterType.VERIFY_ID_WITH_STRIPE;
  }

  if (selectedFiles.length > 0) {
    if (fileSelectionType === FileSelectionType.PDF) {
      return DocumentDetailsFooterType.FINISH_AND_UPLOAD;
    }

    if (fileSelectionType === FileSelectionType.IMAGE) {
      return DocumentDetailsFooterType.ADD_MORE_IMAGES_AND_FINISH_AND_UPLOAD;
    }
  }

  if (isDefined(requirementType) && ReplaceableDocumentRequirementTypes.has(requirementType)) {
    return DocumentDetailsFooterType.UPLOAD_REPLACEMENT;
  }

  return DocumentDetailsFooterType.UPLOAD_DOCUMENT;
}
