import { Title } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { MuiMarkdown } from "@src/appV2/lib";
import { type ReactElement, type ReactNode } from "react";

interface DescriptionProps {
  title: string;
  description: string;
  children?: ReactNode;
}

export function DocumentDetailsSection(props: DescriptionProps): ReactElement {
  const { title, description, children } = props;

  return (
    <Stack spacing={1}>
      <Title component="h2">{title}</Title>
      <Box
        sx={() => ({
          fontSize: (theme) => theme.typography.h3.fontSize,
          lineHeight: (theme) => theme.typography.h3.lineHeight,
          color: (theme) => theme.typography.body2.color,
        })}
      >
        <MuiMarkdown>{description}</MuiMarkdown>
      </Box>
      {children}
    </Stack>
  );
}
