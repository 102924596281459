import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { type ReactNode } from "react";

import { useDocumentDetailsFooterContext } from "./FooterContext";
import { DocumentDetailsFooterType } from "./types";

interface DocumentDetailsDrawerItemsProps {
  setDrawerIsVisible: (drawerIsVisible: boolean) => void;
}

interface DrawerItems {
  content: string;
  icon: ReactNode;
  onClick: () => void;
}

export function DocumentDetailsDrawerItems(props: DocumentDetailsDrawerItemsProps) {
  const { setDrawerIsVisible } = props;

  const { footerType, takeCameraPhoto, selectGalleryPhoto, selectFile } =
    useDocumentDetailsFooterContext();

  const drawerItems: DrawerItems[] = [
    {
      content: "Take a photo",
      icon: <CameraAltIcon />,
      onClick: () => {
        takeCameraPhoto();
        setDrawerIsVisible(false);
      },
    },
    {
      content: "Select a photo from camera roll",
      icon: <CollectionsIcon />,
      onClick: () => {
        selectGalleryPhoto();
        setDrawerIsVisible(false);
      },
    },
  ];

  if (footerType !== DocumentDetailsFooterType.ADD_MORE_IMAGES_AND_FINISH_AND_UPLOAD) {
    drawerItems.push({
      content: "Upload from file manager",
      icon: <FileCopyIcon />,
      onClick: () => {
        selectFile();
        setDrawerIsVisible(false);
      },
    });
  }

  return (
    <List sx={{ width: "auto" }} role="presentation">
      {drawerItems.map((drawerItem) => (
        <ListItem key={drawerItem.content} disablePadding>
          <ListItemButton onClick={drawerItem.onClick}>
            <ListItemIcon>{drawerItem.icon}</ListItemIcon>
            <ListItemText primary={drawerItem.content} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
